var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items.items,"item-key":"id","items-per-page":25,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.items.meta.totalItems,"footer-props":{
      'items-per-page-options': _vm.rowsPerPageItemsGlobal
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-inline-flex justify-space-between align-center pr-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-inline-flex align-center pl-2"},[_c('v-text-field',{staticClass:"pt-2",attrs:{"outlined":"","clearable":"","dense":"","label":_vm.$lang.labels.searchByName,"data-cy":"search-by-name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"pb-2"},[_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('fetchValidations', { options: _vm.options, search: _vm.search && _vm.search.length > 1 ? _vm.search : '', resetPage: true })}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),(_vm.roles.includes('VALIDATION_RULE_CREATOR'))?_c('v-btn',{staticClass:"color-accent-text ml-2",attrs:{"color":"accent","to":{ name: 'validationCreate', params: { lang: _vm.$lang.current_lang } }}},[_vm._v(_vm._s(_vm.$lang.actions.create))]):_vm._e()],1)]},proxy:true},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(item.id))])]}},{key:"item.createdOn",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.createdOn)))])]}},{key:"item.modifiedOn",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.modifiedOn)))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex"},[_c('v-btn',{staticClass:"mx-0 button-default-width",attrs:{"color":"primary","to":{ name: 'validationEdit', params: { id: item.id } }}},[_vm._v(_vm._s(_vm.$lang.actions.edit))]),(_vm.roles.includes('VALIDATION_RULE_CREATOR'))?_c('v-btn',{staticClass:"ml-1 button-default-width color-accent-text",attrs:{"color":"accent"},on:{"click":function($event){_vm.copyCreateResource(item, 'validationCreate', _vm.$router, '', _vm.$options.filters.formatDateTime(new Date()))}}},[_vm._v(_vm._s(_vm.$lang.actions.copyCreate))]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }